<template>
    <div class="container">
        <header class="jumbotron">
            <h3>Dodaj</h3>
        </header>
        <div class="p-fluid p-formgrid p-grid">

            <div class="p-field p-col-12">
                <label for="place">Miejscowość</label>
                <InputText id="place" type="text" v-model="content.place" placeholder="Wpisz miejscowość"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="street">Ulica</label>
                <InputText id="street" type="text" v-model="content.street" placeholder="Wpisz ulicę"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="zipCode">Kod pocztowy</label>
                <InputText id="zipCode" type="text" v-model="content.zipCode" placeholder="Wpisz kod pocztowy"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="buildingNumber">Numer budynku</label>
                <InputText id="buildingNumber" type="text" v-model="content.buildingNumber"
                           placeholder="Wpisz numer budynku"></InputText>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="apartmentNumber">Numer mieszkania</label>
                <InputText id="apartmentNumber" type="text" v-model="content.apartmentNumber"
                           placeholder="Wpisz numer mieszkania"></InputText>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="residents">Liczba mieszkańców </label>
                <InputText id="residents" type="number" min="0" step="1" v-model="content.residents"
                           placeholder="Podaj liczbę mieszkańców"></InputText>
            </div>
        </div>
        <div class="p-field p-col-12 p-md-6 fa-pull-right">
            <Button type="button" class="p-button-secondary" label="Wróć" v-on:click="goBack"></Button>
            <Button type="button" label="Zapisz" v-on:click="click"></Button>
        </div>
    </div>
</template>

<script>
    import DeclarationService from "../../../services/declaration.service";
    import {watch, ref} from "vue";

    export default {
        name: "AddEditDeclaration",
        inject: ['unitTypes', 'global'],
        mode: '',
        props: {
            initialCntent: {
                // eslint-disable-next-line vue/require-valid-default-prop
                type: Object, default: {
                    place: "",
                    street: "",
                    zipCode: "",
                    buildingNumber: "",
                    apartmentNumber: "",
                    quarter: null,
                    year: null
                }
            },
        },
        data() {
            return {
                content: this.initialCntent,
                selectedPeroid: ref(this.global)
            };
        },
        methods: {
            click: function () {
                this.mode = this.$route.params.declarationId ? 'edit' : 'add';
                this.content.street =  this.content.street ? this.content.street : null;
                this.content.apartmentNumber = this.content.apartmentNumber ? this.content.apartmentNumber : null;
                this.content.duplicated = undefined;
                this.content.reported =  undefined;
                this.content.importId =  undefined;
                if (this.mode === 'edit') {
                    DeclarationService.editDeclaration(this.content, this.$route.params.declarationId).then(
                        () => {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Sukces',
                                detail: 'Poprawnie zapisano formularz',
                                life: 3000
                            });
                            this.$router.push({path: `${this.global.state.instancePatch}/formularze-danych/deklaracje/lista`});
                        },
                        (error) => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                } else {
                    DeclarationService.addDeclaration(this.content).then(
                        () => {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Sukces',
                                detail: 'Poprawnie zapisano deklarację',
                                life: 3000
                            });
                            this.$router.push({path: `${this.global.state.instancePatch}/formularze-danych/deklaracje/lista`});
                        },
                        (error) => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            },
            goBack: function () {
                this.$router.push({path: `${this.global.state.instancePatch}/formularze-danych/deklaracje/lista`});
            }
        },
        mounted() {
            this.mode = this.$route.params.declarationId ? 'edit' : 'add';
            this.content.quarter = this.global.state.selectedPeroid.quarter;
            this.content.year = this.global.state.selectedPeroid.year;
            watch(this.selectedPeroid, (val) => {
                if (this.mode === 'add') {
                    this.content.quarter = val.state.selectedPeroid.quarter;
                    this.content.year = val.state.selectedPeroid.year;
                }
            });
            if (this.mode === 'edit') {
                DeclarationService.getDeclaration(this.$route.params.declarationId).then(
                    (response) => {
                        this.content = response.data;
                        this.content.id = undefined;
                        this.content.createdAt = undefined;
                        this.content.updatedAt = undefined;
                        this.content.userCreated = undefined;
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            }

        },
    };
</script>
