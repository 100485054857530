import api from './api';

const API_URL = `/declarations`;

class DeclarationService {
    getAllDeclarations(limit, page, filter) {
        return api.get(API_URL, {
                params: {
                    limit: limit,
                    page: page,
                    quarter: filter.quarter,
                    year: filter.year
                }
            }
        );
    }

    getDeclaration(id) {
        return api.get(`${API_URL}/${id}`
        );
    }

    addDeclaration(content) {
        return api.post(`${API_URL}`, content);
    }

    editDeclaration(content, id) {
        return api.patch(`${API_URL}/${id}`, content);
    }

    removeDeclaration(id) {
        return api.delete(`${API_URL}/${id}`
        );
    }

}

export default new DeclarationService();
